import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


// Local imports
import UserHeader from '../Header/UserHeader/UserHeader'
import Prog from "./Programs.module.scss";
import course from "../../assets/svg/courses-svg.svg";
import instructor from "../../assets/svg/Instructors svg.svg";
import providers from "../../assets/svg/Providers-svg.svg"
import config from "../../config/aws-exports";

const useStyles = makeStyles((theme) => ({

    paper: {
        textAlign: "center",
        verticalAlign: "middle",
        marginLeft: theme.spacing(0),
        marginRight: "0px",
        width: "320px",
        height: theme.spacing(20),
        display: "flex",
        alignItems: "center"
    },


    paper1: {
        textAlign: "center",
        verticalAlign: "middle",
        marginLeft: theme.spacing(0),
        marginRight: "0px",
        width: "320px",
        height: theme.spacing(20),
        display: "flex",
        alignItems: "center",
        borderLeft: "solid 7px var(--sub-heading-color)",
    },


    image: {
        width: "120px",
        backgroundColor: "#E6E6FA",
        padding: "15px",
        borderRadius: "4px"
    },
}));
const Programsdetails = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    let userDetails = useSelector(authData);
    let navigate = useNavigate();


    function titleClick(pgm) {
        let sdata = { ...userDetails };

        sdata.prog = pgm

        console.log("userDetails", userDetails)
        dispatch(awsSignIn(sdata));

        navigate("/programBatchDeatils");
    }


    return (
        <div className={Prog.maincontainer}>
            <UserHeader Bindex={1} />

            <div style={{ padding: "3rem" }}>


                <div>

                    <div style={{ textAlign: "center", margin: "2rem", }} >
                        <h1 className={Prog.heading}>
                            Total
                        </h1>
                    </div>

                    <div style={{ display: "flex", gap: "5rem", margin: "1rem" }}>
                        <Paper elevation={3} className={classes.paper} >
                            <div style={{ padding: "15px", display: "flex", gap: "3rem" }}>
                                <div><img className={classes.image} src={course}></img></div>
                                <div style={{ margin: "auto", textAlign: "left" }}>
                                    <h3 style={{ fontSize: "2.4rem" }}>
                                        {userDetails?.course}
                                    </h3>
                                    <h3 style={{ fontSize: "2.4rem" }}>
                                        Course
                                    </h3>
                                </div>

                            </div>

                        </Paper>
                        <Paper elevation={3} className={classes.paper} >
                            <div style={{ padding: "15px", display: "flex", gap: "3rem" }}>
                                <div><img className={classes.image} src={instructor}></img></div>
                                <div style={{ margin: "auto", textAlign: "left" }}>
                                    <h3 style={{ fontSize: "2.4rem" }}>
                                        {userDetails?.Instructors}
                                    </h3>
                                    <h3 style={{ fontSize: "2.4rem" }}>
                                        Instructors
                                    </h3>
                                </div>

                            </div>
                        </Paper>
                        <Paper elevation={3} className={classes.paper}>
                            <div style={{ padding: "15px", display: "flex", gap: "3rem" }}>
                                <div><img className={classes.image} src={providers}></img></div>
                                <div style={{ margin: "auto", textAlign: "left" }}>
                                    <h3 style={{ fontSize: "2.4rem" }}>

                                    </h3>
                                    <h3 style={{ fontSize: "2.4rem" }}>
                                        Providers
                                    </h3>
                                </div>

                            </div>

                        </Paper>

                    </div>
                </div>

                <div>

                    <div style={{ textAlign: "center", margin: "3rem" }}>
                        <h1 className={Prog.heading}>
                            Programs
                        </h1>
                    </div>

                    <div style={{ display: "flex", gap: "5rem", margin: "2rem" }}>

                        {
                            userDetails.programs.map((pgm) => (<Paper elevation={3} className={classes.paper1} >
                                <div style={{ padding: "15px" }}>
                                    <h2 style={{ cursor: "pointer" }} onClick={() => titleClick(pgm)}>
                                        {pgm.pname}
                                    </h2>
                                </div>

                            </Paper>))
                        }


                    </div>
                </div>
            </div>

        </div>
    )
}

export default Programsdetails
import React, { useState } from 'react'
import TravelFoodForm from '../components/Travel&FoodForm/TravelFoodForm'

function TravelFoodFormLayout() {
    const rtl = false;

    const [toggled, setToggled] = useState(false);

    const handleToggleSidebar = (value) => {
        setToggled(value);
    };
    return (
        <>
            {/* <Aside
              rtl={rtl}
              toggled={toggled}
              handleToggleSidebar={handleToggleSidebar}
          /> */}
            <TravelFoodForm handleToggleSidebar={handleToggleSidebar} />
        </>
    )
}

export default TravelFoodFormLayout
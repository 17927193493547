import React, { useState } from "react";
import {
  AccordionDetails,
} from "@material-ui/core";
import config from "../../../config/aws-exports";
import produce from "immer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./courseView.scss";

import editIcon from "../../../assets/images/edit.png";
import deleteIcon from "../../../assets/images/delete.png";
import eyeIcon from "../../../assets/images/eye.png";

const queryAttr = "data-rbd-drag-handle-draggable-id";
const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer

  width: "calc(100% - 4.5%)",
  display: "flex",
  alignItems: "center",
  margin: "0px 0px 5px 50px",
  fontFamily: "myfontregular",

  background: isDragging ? "#fff" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#fff" : "#fff",
  padding: grid,
  width: "100%",
  position: "relative",
});

const DragAndDrop = ({
  objects,
  idx,
  uidx,
  renderSwitch,
  editObj,
  setRemoveData,
  setNuggets,
  nuggets,
  setIsLoading,
  isLoading,
  setCourseDetails,
  setInopen,
  setData,
  setUnitIndex,
  setObjOpen,
  setSelectedObj,
  refnuggetindex,
  viewAddEditCurrentObject,
  setEditObj,
  userDetails,
  enableDisablePost,
  handleEditOpen,
  setEditType,
  setNTitleIndexAndUTitleIndex,
  setcNugget,
  setInstructorData,
  instructorData
}) => {
  const [Hover, setHover] = useState(false);
  const [placeholderProps, setPlaceholderProps] = useState({});

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const [project, setProject] = useState(objects);

  const onDragEnd = (result) => {
    setEditObj({
      ...editObj,
      meditmode: true,
    });
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    const projects = reorder(
      project,
      result.source.index,
      result.destination.index
    );
    //store reordered state.
    setProject(projects);
    // setNuggets((prevNuggets) => {
    //   return prevNuggets?.map((unit, index) => {
    //     console.log(uidx + "===gg==== " + index);
    //     let temp = { ...unit };

    //     if (uidx === index) {
    //       return temp?.nuggets?.map((nugget, nindex) => {
    //         console.log(idx + "==gfd===== " + nindex);
    //         let temp1 = { ...nugget };
    //         if (idx === nindex) {

    //           temp1.objects = projects;
    //         }
    //         return temp1;
    //       });
    //     }
    //     return temp;
    //   });
    // });
    let pNuggets = [...nuggets];
    console.log(pNuggets);
    for (let index = 0; index < pNuggets.length; index++) {
      const element = pNuggets[index];
      console.log(element, index, uidx);
      if (uidx === index) {

        element.nuggets = projects;
      }
      if (index === pNuggets[uidx].length - 1) {
        setNuggets(pNuggets);
      }
    }
    // setNuggets((prevNuggets) => {
    //   return prevNuggets?.map((nugget, index) => {
    //     console.log(idx + "======= " + index);

    //     let temp = { ...nugget };
    //     return temp[uidx].map((mnugget, mindex) => {
    //       let temp1 = { ...mnugget };
    //     if (idx === mindex) {
    //       temp1.objects = projects;
    //     }
    //     return temp1;
    //   });

    //   });
    // });
  };

  const onDragUpdate = (update) => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      [...draggedDOM.parentNode.children]
        .slice(0, destinationIndex)
        .reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };


  return (
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={getListStyle(snapshot.isDraggingOver)}
          >

            {project?.map(({ enabled, ntitle, nid, objects, instructor, instructors }, ids) => (
              <Draggable draggableId={ids.toString()} index={ids}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div
                      onMouseOver={() => {
                        setHover(true);
                      }}
                      onMouseLeave={() => {
                        setHover(false);
                      }}

                    >
                      <div
                        // onClick={() => handleObjEdit(object, oidx)}
                        style={{ width: '72vw' }}
                      >
                        {/* <div>{renderSwitch(object.otype)}</div>
                        <div className={DND.otitle}>{object.otitle}</div> */}
                        {/* <h3
                          className="edit-icons"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: ".5rem",
                            fontSize: "1.5rem",
                            textAlign: "justify",
                            minHeight: "36px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              maxWidth: "90%",
                            }}
                          >
                            <span style={{ marginRight: "1rem" }}>
                              {renderSwitch(object.otype)}
                            </span>
                            <span>
                              {object.otitle.substring(0, 195) +
                                `${object.otitle.length > 195 ? "..." : ""
                                }`}
                            </span>
                          </div>

                          {userDetails.role != 'CDirector' ?
                            <div className="hide__edit-icon">
                              <span>

                                <button
                                  onClick={() =>
                                    viewAddEditCurrentObject(
                                      object,
                                      uidx,
                                      oidx,
                                      idx,
                                      nuggets[uidx]
                                    )
                                  }
                                  className="btn-reset btn-border"
                                >
                                  <img
                                    src={eyeIcon}
                                    className="size"
                                    alt="edit-eye"
                                  />
                                </button>

                                <button
                                  className="btn-reset btn-border"
                                  onClick={() => {
                                    if (object.otype == "quiz") {
                                      let crobj = { ...object };
                                      crobj.edit = true;

                                      viewAddEditCurrentObject(
                                        crobj,
                                        uidx,
                                        oidx,
                                        idx,
                                        nuggets[uidx]
                                      );

                                    } else {
                                      editObj.oeditmode = true;
                                      viewAddEditCurrentObject(
                                        object,
                                        idx,
                                        oidx,
                                        idx,
                                        nuggets[uidx]
                                      );
                                    }
                                  }}
                                >
                                  <img
                                    src={editIcon}
                                    className="size"
                                    alt="edit-delete"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setNuggets((currentNugget) =>
                                      produce(currentNugget, (draft) => {
                                        draft[uidx].nuggets[
                                          idx
                                        ].objects.splice(oidx, 1);
                                      })
                                    );
                                    setEditObj({
                                      ...editObj,
                                      meditmode: true,
                                    });
                                  }}
                                  className="btn-reset btn-border"
                                >
                                  <img
                                    src={deleteIcon}
                                    className="size"
                                    alt="edit-icon"
                                  />
                                </button>
                              </span>
                            </div> : null}
                        </h3> */}
                        <React.Fragment key={ids + "sdfljkdfgkj"}>
                          <AccordionDetails
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: ".8rem",
                            }}
                          >
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              height: "30px",
                            }}
                            >
                              <h3
                                className="edit-icons"
                                style={{
                                  padding: ".6rem 0 .6rem 2.5rem",
                                  fontWeight: "600",
                                  fontFamily: "sans-serif",
                                  fontSize: "1.5rem",
                                  color: "rgba(0,0,0,.75)",
                                  flexBasis: "90%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  minHeight: "30px",
                                }}
                              >
                                {ntitle.substring(0, 150) +
                                  `${ntitle.length > 150 ? "..." : ""}`}
                                {userDetails.role != 'CDirector' ?
                                  <div className="hide__edit-icon">
                                    <span>
                                      <button
                                        onClick={() => {
                                          handleEditOpen();
                                          setEditType("Module");
                                          setNTitleIndexAndUTitleIndex(
                                            (currentTitle) =>
                                              produce(currentTitle, (v) => {
                                                v.nIdx = ids;
                                                v.uIdx = uidx;
                                              })
                                          );
                                        }}
                                        className="btn-reset btn-border"
                                      >
                                        <img
                                          src={editIcon}
                                          className="size"
                                          alt="edit-delete"
                                        />
                                      </button>
                                      <button
                                        onClick={() => {
                                          setNuggets((currentNugget) =>
                                            produce(currentNugget, (draft) => {
                                              draft[uidx].nuggets.splice(ids, 1);
                                            })
                                          );
                                          setEditObj({ ...editObj, meditmode: true });
                                        }}
                                        className="btn-reset btn-border"
                                      >
                                        <img
                                          src={deleteIcon}
                                          className="size"
                                          alt="edit-icon"
                                        />
                                      </button>
                                    </span>
                                  </div> : null}
                              </h3>
                              {!editObj.meditmode && userDetails.curprgcou.bpid !== undefined && (
                                <button
                                  style={styles.addinstructor}
                                  onClick={() => {
                                    setIsLoading(true);
                                    setInopen(true);
                                    if (instructor?.emailid) {
                                      setCourseDetails((prev) => {
                                        return {
                                          ...prev,
                                          instructorList: prev.instructorList?.map(
                                            (item) => {
                                              if (
                                                item.emailid === instructor?.emailid
                                              ) {
                                                item.select = true;
                                              }
                                              return item;
                                            }
                                          ),
                                        };
                                      });
                                      setData({ emailid: instructor?.emailid });
                                    }
                                    setInstructorData(instructors);
                                    setUnitIndex(uidx);
                                    refnuggetindex.current = ids;
                                    setIsLoading(false);
                                  }}
                                >
                                  {instructors && instructors.length > 0 ? "View Instructors" : "Add Instructors"}
                                </button>
                              )}

                              {!editObj.meditmode && (
                                <button
                                  style={styles.enableDisable}
                                  onClick={(event) => {
                                    enableDisablePost(enabled, nid, ids, uidx);
                                    event.stopPropagation();
                                  }}
                                >
                                  {enabled ? "Disable Module" : "Enable Module"}
                                </button>
                              )}
                            </div>
                            <ul>
                              {objects.map((object, oidx) => (
                                <li
                                  key={oidx + "asdfdfg"}
                                  style={{
                                    marginLeft: "10rem",
                                    minHeight: "40px",
                                    marginBottom: "1.5rem",
                                  }}
                                >
                                  <h3
                                    className="edit-icons"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      padding: ".5rem",
                                      fontSize: "1.5rem",
                                      textAlign: "justify",
                                      minHeight: "36px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        maxWidth: "90%",
                                      }}
                                    >
                                      <span style={{ marginRight: "1rem" }}>
                                        {renderSwitch(object.otype)}
                                      </span>
                                      <span>
                                        {object.otitle.substring(0, 195) +
                                          `${object.otitle.length > 195 ? "..." : ""
                                          }`}
                                      </span>
                                    </div>

                                    <div className="hide__edit-icon">
                                      <span>

                                        <button
                                          onClick={() =>
                                            viewAddEditCurrentObject(
                                              object,
                                              uidx,
                                              oidx,
                                              ids,
                                              project
                                            )
                                          }
                                          className="btn-reset btn-border"
                                        >
                                          <img
                                            src={eyeIcon}
                                            className="size"
                                            alt="edit-eye"
                                          />
                                        </button>

                                        {userDetails.role != 'CDirector' ?
                                          <span><button
                                            className="btn-reset btn-border"
                                            onClick={() => {
                                             
                                              if (object.otype == "quiz") {
                                                let crobj = { ...object };
                                                crobj.edit = true;

                                                viewAddEditCurrentObject(
                                                  crobj,
                                                  uidx,
                                                  oidx,
                                                  ids,
                                                  project
                                                );

                                              } else {
                                                editObj.oeditmode = true;
                                                viewAddEditCurrentObject(
                                                  object,
                                                  uidx,
                                                  oidx,
                                                  ids,
                                                  project
                                                );
                                              }
                                            }}
                                          >
                                            <img
                                              src={editIcon}
                                              className="size"
                                              alt="edit-delete"
                                            />
                                          </button>
                                            <button
                                              onClick={() => {
                                                setNuggets((currentNugget) =>
                                                  produce(currentNugget, (draft) => {
                                                    draft[uidx].nuggets[
                                                      ids
                                                    ].objects.splice(oidx, 1);
                                                  })
                                                );
                                                setEditObj({
                                                  ...editObj,
                                                  meditmode: true,
                                                });
                                              }}
                                              className="btn-reset btn-border"
                                            >
                                              <img
                                                src={deleteIcon}
                                                className="size"
                                                alt="edit-icon"
                                              />
                                            </button>
                                          </span> : null}
                                      </span>
                                    </div>
                                  </h3>
                                </li>
                              ))}
                            </ul>
                            {userDetails.role != 'CDirector' ? <div
                              style={{
                                margin: "2rem 0 0 10rem",
                                height: "30px",
                              }}
                            >
                              <button
                                onClick={() => {
                                
                                  setcNugget({
                                    nid,
                                    ntitle,
                                    objects,
                                    ids,
                                    uidx,
                                    cedit: true,
                                  });
                                  setNuggets(nuggets)
                                  console.log("nuggets",uidx,ids);
                                  setObjOpen(true);
                                }}
                                style={styles.enableDisable}
                              >
                                Add object
                              </button>
                            </div> : null}
                          </AccordionDetails>
                        </React.Fragment>

                      </div>

                    </div>

                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
let styles = {
  editButton: {
    color: "white",
    borderStyle: "groove ",
    borderColor: config.main_color_2,
    height: "30px ",
    lineHeight: "0px ",
    width: "150px",
    marginTop: "15px",
    marginRight: "50px",
    backgroundColor: config.main_color_2,
    fontSize: "15px ",
    borderRadius: "3px ",
    float: "right",
    fontWeight: "bold",
    fontFamily: "nunito",
  },
  enableDisable: {
    color: "#f18121",
    border: "1px solid #f18121",
    backgroundColor: "white",
    fontSize: "15px ",
    borderRadius: "3px ",
    padding: ".3rem 2rem",
    alignSelf: "center",
    fontWeight: "bold",
    fontFamily: "nunito",
  },
  addinstructor: {
    color: "#f18121",
    border: "1px solid #f18121",
    backgroundColor: "white",
    fontSize: "15px ",
    borderRadius: "3px ",
    padding: ".3rem 2rem",
    alignSelf: "center",
    fontWeight: "bold",
    marginRight: "4px",
    fontFamily: "nunito",
  },
};
export default DragAndDrop;

// Dependencies imports
import React, { useEffect, useState } from "react";
import { Tab } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import PropTypes from "prop-types";
import config from "../../config/aws-exports";
import Skeleton from "@material-ui/lab/Skeleton";
import { Constants } from "../../config/constants";
import { API } from "aws-amplify";
import ViewSurvey from "./ViewSurvey";
import DailyFileUpload from "../Users/DailyFileUpload";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
// Style imports
import Rep from "./Reports.module.scss";
import {
  makeStyles,
  useTheme,
  IconButton,
  Paper,
  CircularProgress,
  Button,
  Backdrop,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import StudentView from "./studentView";
import FeedbackByOthers from "./FeedbackByOthers";
import ModuleWiseFeedBack from "./ModuleWiseFeedBack";
import { Rating } from "semantic-ui-react";
import Fac from "../Programs/Faculty.module.scss";
import ProvideFeeback from "../FeedBack/ProvideFeedback";
import ViewScores from "../Programs/viewscores";
import ViewComments from "../FeedBack/ViewComments";
import moment from "moment";
import Swal from "sweetalert2";
import ViewDoc from "./ViewDoc";

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  tabcss: {
    overflow: "auto",
  },
});
const header = {
  color: "white",
  fontFamily: "nunito",
  fontSize: "15px",
  fontWeight: "400px",
};
// Start of Reports component
const Reports = () => {
  const column = [
    {
      field: "first_name",
      headerName: "Name",
      width: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: true,
      renderCell: (params) => <p style={{ fontSize: "14px" }} onClick={handleCellClick}>{params.value}</p>,
    },
    {
      field: "emailid",
      headerName: "Email",
      width: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: true,
      renderCell: (params) => <p>{params.value}</p>,
    },
    {
      field: "cno",
      headerName: "Contact No",
      width: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: true,
      renderCell: (params) => <p>{params.value}</p>,
    },
    {
      field: "regi_dtls",
      headerName: "Education",
      width: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: true,
      renderCell: (params) =>
        params?.value !== null ? (
          <p>
            {params?.value?.Education === undefined
              ? params.value[0]?.Education
              : params.value?.Education}
          </p>
        ) : (
          <p></p>
        ),
    },
    {
      field: "payment",
      headerName: "Payment Status",
      width: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: true,
      renderCell: (params) => <p>{params.value ? "Paid" : "Unpaid"}</p>,
    },
    {
      field: "scores3",
      headerName: "Pre Test",
      width: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: true,
      renderCell: (params) => {
        return (
          <>
            <div style={{ display: "grid" }}>
              {typeof params?.value === 'string' || typeof params?.value === 'number' ? (
                <p>{params?.value}</p>
              ) : (
                params?.value != null && params?.value?.map((value, index) => (
                  <span key={index} style={{ fontSize: "12.5px" }}>{value} </span>
                ))
              )}
            </div>
          </>
        );
      }
    },
    {
      field: "scores1",
      headerName: "Post Test",
      width: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: true,
      renderCell: (params) => {
        return (
          <>
            <div style={{ display: "grid" }}>
              {params?.value != null && params?.value?.map((value, index) => {
                return (

                  <span style={{ fontSize: "8px" }}>Attempt {index + 1} - {value} </span>

                );
              })}
            </div>

          </>
        );
      }
    },
    {
      field: "scores2",
      headerName: "Practical Exam",
      width: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: true,
      renderCell: (params) => {
        return (
          <>
            <div style={{ display: "grid" }}>
              {params?.value != null && params?.value.map((value, index) => {
                return (

                  <span style={{ fontSize: "12.5px" }}>Attempt {index + 1} - {value} </span>

                );
              })}
            </div>

          </>
        );
      }
    },
    {
      field: "attempts",
      headerName: "Attempt",
      width: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: true,
      renderCell: (params) => {
        return (
          <>
            <div>
              <Button
                variant="outlined"
                color="primary"
                disabled={
                  params.value == null ||
                  params.value == 1 ||
                  params.value == "approved" ||
                  params.value == "pass"
                }
                onClick={() => {
                  approveQuiz(params);
                }}
              >
                Approve ({params.value})
              </Button>
            </div>

          </>
        );
      }
    },
  ];

  const classes = useStyles2();
  const dispatch = useDispatch();
  let userDetails = useSelector(authData);
  const [courseList, setCourseList] = useState();

  const [loading, setLoading] = useState(false);
  const [cLoading, setCloading] = useState(false);
  const [sfdLoading, setSfdLoading] = useState(false);
  const [otdLoading, setOtdLoading] = useState(false);
  const [inopen, setInopen] = useState(false);
  const [snopen, setSnopen] = useState(false);

  const [surveyData, setSurveData] = useState();
  const [scoreData, setScoreData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [moduleData, setModuleData] = useState([]);
  const [iFeedBackData, setIfeedBackData] = useState();
  const [oFeedBackData, setOfeedBackData] = useState();
  const [CFeedBackData, setCfeedBackData] = useState();
  const [courseid, setCourseId] = useState();
  const [value, setValue] = React.useState(0);
  const [uname, setUname] = useState();
  const [colums, setColums] = useState(column);
  const [Cdfeedback, setCdFeedback] = useState([]);
  const [sendLoading, setSendLoading] = useState(false);
  const [cmtFeedback, setCmtFeedback] = useState({
    cmts: "",
    name: "",
    rate: 0,
    headerName: "",
  });

  const rows = React.useRef([]);
  const crid = React.useRef();
  const [pnopen, setpnopen] = useState(false);
  const [cmtOpen, setCmtOpen] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [markLists, setMarkLists] = useState([]);
  const [selectedCurlFile, setSelectedCurlFile] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [co_id, setco_id] = useState();
  const [showApproveButton, setShowApproveButton] = useState(false)
  const [approveObject, setApproveObject] = useState(false)


  useEffect(() => {
    breadcrumb();
    getOtherFeedBack("Instructor");
    getOtherFeedBack("Observer");
    if (userDetails.role != "CDirector") {
      getOtherFeedBack("CDirector");
    }
    getFeedbackquestions();
    getCourseList();
    getDailyDebrief();
  }, []);

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: "Reports",
      path: "/Reports",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }
  const handleCellClick = (param, event) => { };

  const handleChange = (event, newValue) => {
    // if (courseid) {
    //   getTabData(newValue.activeIndex,courseid);
    //   setValue(newValue);
    // }
  };

  const approveQuiz = async (param) => {

    var ind = param?.row?.objects.findIndex(object => object.qtype == 3);
    var objid, attem;

    if (ind != -1) {

      objid = param.row.objects[ind].obj_id;
      attem = parseInt(param.row.objects[ind].attempt) + 1;
    }
    setLoading(true);
    const bodyParam = {
      body: {
        key: crid.current,
        obj_id: objid,
        ur_id: param.row.ur_id,
        attemptstatus: "approved",
        batchId: userDetails?.curprgcou?.bid,
        schema: config.schema,
        op: 1,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const viewsurvey = async (param) => {
    setLoading(true);
    const bodyParam = {
      body: {
        cid: crid.current,
        ur_id: param.row.ur_id,
        attempt: "approved",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    setUname(param.row.first_name);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getSurvey",
        bodyParam
      );
      setSurveData(response);

      setInopen(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  async function getTabData(ind, cid) {

    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid,
        bpid: userDetails.curprgcou.bpid,
        cid: cid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    var apiname = "";
    if (ind === 0) {
      apiname = "/getUsersCourseReport";
    } else if (ind === 1) {
      apiname = "/getUsersCourseReport";
    }
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        apiname,
        bodyParam
      );
      if (ind === 0) {
        rows.current = [...response.body];
        convertData(response.body);

        if (response.body && response.body.length > 0) {
          if (response.body[0].objects !== null) {
            var obj = {};
            let clm = [...column];
            obj = {};
            obj.field = "survey";
            obj.headerName = "Survey results";
            obj.minWidth = 60;
            obj.flex = 1;
            obj.headerClassName = "super-app-theme--header";
            obj.editable = true;
            obj.renderCell = (params) => {
              return (
                <Button
                  color="primary"
                  onClick={() => {
                    viewsurvey(params);
                  }}
                >
                  View
                </Button>
              );
            };
            clm.push(obj);
            setColums(clm);
          }
        }
      } else if (ind === 1) {
        setUserData(response.body);
      }
      setLoading(false);
    } catch (error) {
      console.log("getCategoryError", error);
    }
  }

  async function getDailyDebrief() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getDailyDebrief",
        bodyParam
      );

      if (response.lists !== undefined && response.lists.length > 0) {
        setMarkLists(response.lists);
      }

    } catch (error) {
      console.error(error);
    }
  }

  async function getCourseList() {
    setCloading(true);
    const bodyParam = {
      body: {
        bpid: userDetails.curprgcou.bpid,
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const Clist = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_COURSELIST,
        bodyParam
      );

      setCourseList(Clist?.courses);
      setCloading(false);
    } catch (error) {
      console.error(error);
    }
  }
  async function getStudentFeedBack() {
    setSfdLoading(true);
    const bodyParam = {
      body: {
        bpid: userDetails.curprgcou.bpid,
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid,
        cid: crid.current,
        ur_id: userDetails?.uData?.ur_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const slist = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getStudentFeedBack",
        bodyParam
      );

      if (slist?.body && slist.body?.length > 0) {
        for (let i = 0; i < slist.body.length; i++) {
          if (
            slist.body[i]?.students !== undefined &&
            slist.body[i].students !== null
          ) {
            for (let k = 0; k < slist.body[i].students.length; k++) {
              if (slist.body[i].students[k].fd) {
                for (let j = 0; j < slist.body[i].students[k].fd.length; j++) {
                  if (
                    slist.body[i].students[k].fd[j] &&
                    Object.keys(slist.body[i].students[k].fd[j]).length > 0
                  ) {
                    let sval =
                      slist.body[i].students[k].fd[j][
                      Object.keys(slist.body[i].students[k].fd[j])
                      ];
                    sval = sval === undefined || sval == null ? 0 : sval;

                    slist.body[i].students[k][
                      Object.keys(slist.body[i].students[k].fd[j])
                    ] = sval;
                  }
                }
              }
              slist.body[i].students[k].id = k;
            }
            var obj = {};
            obj.field = "first_name";
            obj.headerName = "";
            obj.width = 100;
            obj.minWidth = 80;
            obj.flex = 1;
            obj.headerClassName = "super-app-theme--header";
            obj.editable = false;
            obj.renderCell = (params) => {
              return <p style={{ fontSize: "14px" }}>{params.value}</p>;
            };
            slist.body[i].columns.unshift(obj);
            for (let l = 1; l < slist.body[i]?.columns?.length; l++) {
              slist.body[i].columns[l].renderCell = (params) => {
                return (
                  <p
                    onClick={() => {
                      let adcmts = { ...cmtFeedback };

                      if (params.row.cmts !== undefined) {
                        params.row.cmts.map((item) => {
                          if (
                            Object.keys(item) !== null &&
                            Object.keys(item) == params.field
                          ) {
                            adcmts.cmts = item[Object.keys(item)];
                          }
                        });
                        adcmts.name = params.row.first_name;
                        adcmts.rate = params.value;
                        adcmts.headerName = params.colDef.headerName;
                        setCmtFeedback(adcmts);
                        setCmtOpen(true);
                      }
                    }}
                  >
                    <Rating
                      maxRating={5}
                      defaultRating={params.value}
                      disabled
                      icon="star"
                      size="large"
                    />
                  </p>
                );
              };
            }
          }
        }
      }

      setModuleData(slist?.body);
      setSfdLoading(false);
    } catch (error) {
      console.log("getStudentFeedBack1err==", error);
    }
  }
  async function getFeedbackquestions() {
    setCloading(true);
    const bodyParam = {
      body: {
        action: 1,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const Flist = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getFeedbackQuestions",
        bodyParam
      );

      setCdFeedback(Flist?.cdfdetails);
      setCloading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const gFunction = async (data) => {
    setSendLoading(true);
    const bodyParam = {
      body: {
        eid: data?.eid,
        uname: data?.first_name,
        type: "instructor||observer",
        cname: data?.cname,
        des: `course (JR,KA,B/048   )held at St Joseph's College of Nursing, Mysuru on ${moment(
          parseInt(data?.start_date)
        ).format("DD-MM-YYYY")} and is certified as '${data?.cname
          } PROVIDER'.Certificate is valid for 5 years from this day.`,
        emailid: data?.emailid,
        domain: config.DOMAIN,
        cid: userDetails.curprgcou.bpid,
        ur_id: data?.ur_id,
        pid: userDetails.curprgcou.pid,
        date: moment(parseInt(data?.start_date)).format("DD-MM-YYYY"),
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

    };

    try {
      const res = await API.post(
        config.aws_cloud_logic_custom_name,
        "/generatecertificate",
        bodyParam
      );
      setSendLoading(false);

      getOtherFeedBack("Instructor");
      getOtherFeedBack("Observer");
    } catch (error) {
      console.error(error);
      setSendLoading(false);
    }
  };

  const handleGenarateCertificate = async (data) => {

    Swal.fire({
      title: "Send Certificate",
      onfirmButtonText: "Send",
      showLoaderOnConfirm: true,
      preConfirm: () => gFunction(data),
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((ok) => {
      if (ok.isConfirmed) {
        Swal.fire(
          "Good job!",
          "The certificate was sent successfully.!",
          "success"
        );
      }
    });
  };
  async function getOtherFeedBack(utype) {
    const bodyParam = {
      body: {
        bpid: userDetails.curprgcou.bpid,
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid,
        cid: crid.current,
        utype,
        ur_id: userDetails?.uData?.ur_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const slist = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getFeedBack",
        bodyParam
      );
      if (slist?.body?.columns !== undefined) {
        for (let i = 1; i < slist?.body?.columns?.length; i++) {
          slist.body.columns[i].renderCell = (params) => {
            return (
              <p
                onClick={() => {
                  let adcmts = { ...cmtFeedback };

                  if (params.row.id !== 0 && params.row.cmts !== undefined) {
                    params.row.cmts.map((item) => {
                      if (Object.keys(item) == params.field) {
                        adcmts.cmts = item[Object.keys(item)];
                      }
                    });
                    adcmts.name = params.row.first_name;
                    adcmts.rate = params.value;
                    adcmts.headerName = params.colDef.headerName;
                    setCmtFeedback(adcmts);
                    setCmtOpen(true);
                  }
                }}
              >
                <Rating
                  maxRating={5}
                  defaultRating={params.value}
                  disabled
                  icon="star"
                  size="large"
                />
              </p>
            );
          };
        }
      }
      let obj = {};
      obj.field = "send";
      obj.headerName = "certificate";
      obj.width = 100;
      obj.minWidth = 80;
      obj.flex = 1;
      obj.headerClassName = "super-app-theme--header";
      obj.editable = false;
      obj.renderCell = (params) => {
        return (
          <>
            {params.row?.ur_id && !params.value && (
              <Button
                variant="contained"
                size="small"
                style={{
                  marginLeft: 16,
                  background: config.main_color_2,
                  color: "white",
                  fontSize: "14px",
                  fontFamily: "nunito",
                }}
                disabled={!window.navigator.onLine && loading ? true : false}
                onClick={() => handleGenarateCertificate(params.row)}
              >
                {loading ? "Loading..." : "Send"}
              </Button>
            )}
          </>
        );
      };
      if (utype === "Instructor") {
        const data = { ...slist?.body, columns: [...slist?.body.columns, obj] };
        if (data.users?.length > 0) {
          let array = [];
          for (let i = 0; i < data.users?.length; i++) {
            if (data.users[i].roles?.length > 0) {
              for (let j = 0; j < data.users[i].roles?.length; j++) {
                if (data.users[i].roles[j].role == utype && data.users[i].roles[j].batchid == userDetails?.curprgcou?.bpid) {
                  array.push(data.users[i]);
                }
              }
            }
          }
          data.users = array;
        }
        setIfeedBackData(data);
      } else if (utype === "Observer") {
        const data = { ...slist?.body, columns: [...slist?.body.columns, obj] };

        if (data.users?.length > 0) {
          let array = [];
          for (let i = 0; i < data.users?.length; i++) {
            if (data.users[i].roles?.length > 0) {
              for (let j = 0; j < data.users[i].roles?.length; j++) {
                if (data.users[i].roles[j].role == utype && data.users[i].roles[j].batchid == userDetails?.curprgcou?.bpid) {
                  array.push(data.users[i]);
                }
              }
            }
          }
          data.users = array;
        }
        setOfeedBackData(data);
        if (userDetails.role != "Admin") {
          setOtdLoading(false);
        }
      } else {
        setCfeedBackData(slist?.body);
        setOtdLoading(false);
      }
    } catch (error) {
      console.log("error==", error);
      setOtdLoading(false);
    }
  }
  const convertData = (data) => {
    var tmp = [];
    console.log("data", data)

    let tempCount = 0;

    let tempObj = [];


    for (let i = 0; i < data.length; i++) {
      if (data[i].objects != undefined) {
        for (let j = 0; j < data[i].objects.length; j++) {
          if (data[i]?.objects[j]?.qtype == 3 && data[i]?.objects[j]?.op == 1) {

            obj = {}
            obj.ur_id = data[i].ur_id
            obj.obj_id = data[i].objects[j].obj_id

            tempObj.push(obj)
            tempCount++;
            break;
          }
        }
      }
    }

    console.log("tempObj", tempObj)

    setApproveObject(tempObj)
    if (tempCount == data.length) {
      setShowApproveButton(true);

    }

    console.log("count", tempCount)

    data.forEach((element) => {
      var obj = element;
      if (element.objects != null) {
        element.objects.forEach((ele) => {
          var tobj = { ...ele };
          delete tobj.iname;
          delete tobj.qtype;
          delete tobj.otitle;

          console.log("ele", ele)
          for (var m = 0; m < Object.keys(tobj).length; m++) {
            if (Object.keys(tobj)[m] !== undefined) {
              obj[Object.keys(tobj)[m]] = tobj[Object.keys(tobj)[m]];
            }
          }
        });
      }
      tmp.push(obj);
    });
    setUserData(tmp);
  };

  const handleFileOpen = () => {
    setOpenFile(true);
  };

  const handleFileClose = () => {
    setOpenFile(false);
  };

  const tableview = () => {
    return (
      <TableBody>
        {markLists.map((semester) => (
          <TableRow key={semester.id}>
            <TableCell style={{ width: "33%", fontSize: "15px" }}>{semester.title} </TableCell>

            <TableCell style={{ width: "33%", fontSize: "15px" }}>
              <a
                style={{ fontSize: "15px", cursor: "pointer", color: "blue" }}
                onClick={() => {
                  setSelectedCurlFile(semester.file);
                  setSelectedFile(semester.file)
                  handleAddOpen();

                }}
              >
                {semester.file}
              </a>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };
  const handleAddOpen = () => {
    setOpenAdd(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
  };


  // Data for tabs
  const panes = [
    {
      menuItem: "Student analytics",
      render: () => (
        <Tab.Pane active={true}>
          {loading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress style={{ color: config.main_color_1 }} />
            </div>
          )}
          {courseid && !loading && (
            <StudentView userData={userData} columns={colums} showApproveButton={showApproveButton} approveObject={approveObject} />
          )}
          {!courseid && <><p style={{ fontSize: '15px', fontWeight: 500, textAlign: "center" }}>Select Course Day</p></>}
          <ViewSurvey
            inopen={inopen}
            setInopen={setInopen}
            loading={loading}
            setLoading={setLoading}
            surveyData={surveyData}
            setSurveData={setSurveData}
            uname={uname}
          />
          <ViewScores
            snopen={snopen}
            setSnopen={setSnopen}
            loading={loading}
            setLoading={setLoading}
            scoreData={scoreData}
            setScoreData={setScoreData}
            uname={uname}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Feedback",
      render: () => (
        <Tab.Pane active={true}>
          {!otdLoading &&
            userDetails.role == "CDirector" &&
            !iFeedBackData?.cfdstatus &&
            iFeedBackData !== undefined &&
            iFeedBackData?.users?.length > 0 ? (
            <button
              className={
                window.navigator.onLine === true
                  ? Fac.btn_color
                  : Fac.btn_colordis
              }
              disabled={!window.navigator.onLine}
              style={{ float: "right", margin: "5px" }}
              onClick={() => setpnopen(true)}
            >
              Provide feedback
            </button>
          ) : null}

          <ProvideFeeback
            inopen={pnopen}
            setInopen={setpnopen}
            Cdfeedback={Cdfeedback}
            cid={userDetails.curprgcou?.bpid}
            setLoading={setLoading}
            getOtherFeedBack={getOtherFeedBack}
          />
          <ViewComments
            cmtOpen={cmtOpen}
            setCmtOpen={setCmtOpen}
            cmtFeedback={cmtFeedback}
            setCmtFeedback={setCmtFeedback}
          />
          <Tab panes={fdpanes} />
        </Tab.Pane>
      ),
    },
  ];
  const fdpanes = [
    {
      menuItem: "Student Feedback",
      render: () => (
        <Tab.Pane active={true} className={classes.tabcss}>
          {sfdLoading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress style={{ color: config.main_color_1 }} />
            </div>
          )}
          {!sfdLoading && moduleData !== null && moduleData?.length > 0 && (
            <ModuleWiseFeedBack moduleData={moduleData} />
          )}
          {!sfdLoading && moduleData !== null && moduleData?.length <= 0 && (
            <><p style={{ fontSize: '15px', fontWeight: 500, textAlign: "center" }}>No Data</p></>
          )}

        </Tab.Pane>
      ),
    },
    {
      menuItem: "Instructor Feedback",
      render: () => (
        <Tab.Pane active={true}>
          <div></div>
          {otdLoading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress style={{ color: config.main_color_1 }} />
            </div>
          )}
          {!otdLoading &&
            iFeedBackData !== null &&
            iFeedBackData?.users?.length > 0 && (
              <FeedbackByOthers
                iFeedBackData={iFeedBackData}
                vtype="1"
                cid={crid.current}
                Cdfeedback={Cdfeedback}
                setLoading={setLoading}
                getOtherFeedBack={getOtherFeedBack}
              />
            )}
          {!otdLoading &&
            (iFeedBackData == null || iFeedBackData?.users?.length <= 0) && (
              <><p style={{ fontSize: '15px', fontWeight: 500, textAlign: "center" }}>No Data</p></>
            )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Observer Feedback",
      render: () => (
        <Tab.Pane active={true}>
          {otdLoading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress style={{ color: config.main_color_1 }} />
            </div>
          )}
          {!otdLoading &&
            oFeedBackData !== null &&
            oFeedBackData?.users?.length > 0 && (
              <FeedbackByOthers
                oFeedBackData={oFeedBackData}
                vtype="2"
                sfdLoading={sfdLoading}
              />
            )}

          {!otdLoading &&
            (oFeedBackData == null || oFeedBackData?.users?.length <= 0) && (
              <><p style={{ fontSize: '15px', fontWeight: 500, textAlign: "center" }}>No Data</p></>
            )}
        </Tab.Pane>
      ),
    },
  ];
  if (userDetails.role == "Admin") {
    var obj = {
      menuItem: "Course Director Feedback",
      render: () => (
        <Tab.Pane active={true}>
          {otdLoading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress style={{ color: config.main_color_1 }} />
            </div>
          )}
          {!otdLoading &&
            CFeedBackData !== null &&
            CFeedBackData?.users?.length > 0 && (
              <FeedbackByOthers
                CFeedBackData={CFeedBackData}
                vtype="3"
                sfdLoading={sfdLoading}
              />
            )}

          {!otdLoading &&
            (CFeedBackData == null || CFeedBackData?.users?.length <= 0) && (
              <><p style={{ fontSize: '15px', fontWeight: 500, textAlign: "center" }}>No Data</p></>
            )}
        </Tab.Pane>
      ),
    };
    fdpanes.push(obj);
  }
  var obj = {
    menuItem: "Daily De-brief",
    render: () => (
      <Tab.Pane active={true}>
        <div className={Rep.rightsection}>
          {userDetails.role != 'Admin' ? <button className={
            window.navigator.onLine === true
              ? Rep.btn_color
              : Rep.btn_colordis
          }
            style={{ marginBottom: "20px" }}
            disabled={!window.navigator.onLine}
            onClick={() => {
              handleFileOpen();
            }}
          >
            Upload
          </button> : null}
        </div>
        {otdLoading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress style={{ color: config.main_color_1 }} />
          </div>
        )}
        {!otdLoading && (
          <div >
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ background: config.main_color_1 }}>
                  <TableRow>

                    <TableCell style={header}>
                      Title
                    </TableCell>
                    <TableCell style={header}>
                      File Name
                    </TableCell>
                  </TableRow>
                </TableHead>
                {tableview()}
              </Table>
            </TableContainer>
          </div>
        )}

        {!otdLoading &&
          (markLists == null || markLists?.length <= 0) && (
            <><p style={{ fontSize: '15px', fontWeight: 500, textAlign: "center" }}>No Data</p></>
          )}
      </Tab.Pane>
    ),
  };
  fdpanes.push(obj);
  return (
    <div className={Rep.maincontainer}>
      <UserHeader Bindex={2} />
      <h3 className={Rep.heading}>Reports</h3>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdd}
        closeAfterTransition
      >
        <Fade in={openAdd}>
          <ViewDoc
            handleAddClose={handleAddClose}
            docFileName={selectedFile}
            docCurlFileName={selectedCurlFile}
            type={'reports'}
          />
        </Fade>
      </Modal>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "flex-start",
          padding: "10px 0px",
          gap: "20px",
        }}
      >
        <h3 style={{ width: "150px" }}>Select Course Day</h3>
        {!cLoading && (
          <select
            className="classInput_assign_assess"
            style={{
              marginBottom: "0",
              width: "250px",
              minWidth: "200px",
              borderRadius: "5px",
              height: "30px",
              fontSize: '14px',
              padding: '5px'
            }}
            onChange={(event) => {
              setCourseId(event.target.value);
              crid.current = event.target.value;
              setco_id(event.target.value)
              getTabData(value, event.target.value);
              getStudentFeedBack();
            }}
          >
            <option value="0" selected="selected" disabled>
              Select Course Day
            </option>

            {courseList?.map((value, index) => {
              return (
                <option value={value.cid} key={"curr" + index}>
                  {value.title}
                </option>
              );
            })}
          </select>
        )}
        {cLoading && <Skeleton variant="rect" width="25%" height={25} />}
      </div>
      <div className={Rep.gridholder}>
        <Tab panes={panes} onTabChange={handleChange} />
      </div>

      <Backdrop open={sendLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openFile}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openFile}>
          <DailyFileUpload handleClose={handleFileClose} getDailyDebrief={getDailyDebrief}
          />
        </Fade>
      </Modal>
    </div>
  );
};

export default Reports;

import React, { useRef, useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import { API, Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import autoTable from 'jspdf-autotable';
import { CircularProgress } from "@material-ui/core";

import UserHeader from '../Header/UserHeader/UserHeader'
import Prog from "./TravelFoodForm.module.scss";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { duration } from 'moment';


const TravelFoodForm = () => {

    const formRef = useRef();
    const divRef = useRef();

    const [hdata, sethdata] = useState({
        uname: "",
        phaddress: "",
        pcno: "",
        pdesignation: "",
        pinstitution: null,
        course_dtls: null,
        pbank_name: "",
        pbank_address: "",
        pacc_no: "",
        pifsc_code: "",
        ppan_no: "",
    });

    const [cdata, setCdata] = useState({

    })

    const [loading, setLoading] = useState(false);


    let userDetails = useSelector(authData);

    console.log("userDetails", userDetails)

    useEffect(() => {
        getHonorariumForm();
    }, []);

    async function getHonorariumForm() {
        setLoading(true);

        const bodyParam = {
            body: {
                ur_id: userDetails.uData.ur_id,
                oid: config.aws_org_id,
                schema: config.schema,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };

        try {
            const honorariumDetails = await API.post(
                config.aws_cloud_logic_custom_name,
                '/getHonorarium',
                bodyParam
            );

            console.log("honorariumDetails", honorariumDetails.body[0]);

            if (
                honorariumDetails &&
                honorariumDetails.body &&
                honorariumDetails.body.length > 0
            ) {
                const details = honorariumDetails.body[0];
                console.log("honorariumDetail", details.uname);

                sethdata({
                    ...hdata,
                    uname: details.uname,
                    phaddress: details.haddress,
                    pcno: details.cno,
                    pdesignation: details.designation,
                    pinstitution: details.institution,
                    pbank_name: details.bank_name,
                    pbank_address: details.bank_address,
                    pacc_no: details.acc_no,
                    pifsc_code: details.ifsc_code,
                    ppan_no: details.pan_no,

                });

                setCdata({
                    ...cdata,
                    course: details.course_dtls.course,
                    institution: details.course_dtls.institution,
                    duration: details.course_dtls.duration,
                    daysAttended: details.course_dtls.daysAttended,
                    attendee: details.course_dtls.attendee
                });
            }
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
    }

    async function updateHonorariumForm() {
        const bodyParam = {
            body: {
                urid: userDetails.uData.ur_id,
                oid: config.aws_org_id,
                schema: config.schema,
                uname: hdata.uname,
                course_dtls:
                {
                    "course": cdata.course,
                    "institution": cdata.course,
                    "duration": cdata.pStart - cdata.pend,
                    "daysAttended": cdata.daysAttended,
                    "attendee": cdata.attendee
                },
                phaddress: hdata.phaddress,
                pcno: hdata.pcno,
                pdesignation: hdata.pdesignation,
                pbank_name: hdata.pbank_name,
                pbank_address: hdata.pbank_address,
                pacc_no: hdata.pacc_no,
                pifsc_code: hdata.pifsc_code,
                ppan_no: hdata.ppan_no,
                pinstitution: hdata.pinstitution,

            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };

        console.log("bodyParam", bodyParam.body)

        try {
            const honorariumDetails = await API.post(
                config.aws_cloud_logic_custom_name,
                '/updateHonorarium',
                bodyParam
            );

            console.log("honorariumDetails", honorariumDetails)

        } catch (error) {
            console.error(error);
        }
    }

    const generateHonorariumPDF = () => {
        const pdf = new jsPDF();
        let currentY = 20; // Tracks the current vertical position

        // Setting fonts and title
        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(18);
        pdf.text('JeevaRaksha Trust', 105, currentY, { align: 'center' });
        currentY += 10;

        pdf.setFontSize(16);
        pdf.setTextColor(255, 0, 0);
        pdf.text('Honorarium Form', 105, currentY, { align: 'center' });
        currentY += 10;

        pdf.setFontSize(14);
        pdf.setTextColor(0, 0, 0);
        pdf.text('(for Course Director)', 105, currentY, { align: 'center' });
        currentY += 20;

        pdf.setFontSize(12);
        pdf.text(`Date: ${new Date().toLocaleDateString()}`, 180, currentY, { align: 'right' });
        currentY += 10;

        // Faculty Details Section
        pdf.setFontSize(14);
        pdf.text('Faculty Details', 20, currentY);
        currentY += 10;

        pdf.setFontSize(12);
        pdf.text(`1. Name: ${hdata.uname}`, 20, currentY);
        currentY += 10;
        pdf.text(`2. Address: ${hdata.phaddress}`, 20, currentY);
        currentY += 10;
        pdf.text(`3. Contact No: ${hdata.pcno}`, 20, currentY);
        currentY += 10;
        pdf.text(`4. Designation: ${hdata.pdesignation}`, 20, currentY);
        currentY += 10;
        pdf.text(`5. Institution: ${hdata.pinstitution}`, 20, currentY);
        currentY += 20;

        // Add page if content exceeds the page height
        if (currentY > 270) {
            pdf.addPage();
            currentY = 20; // Reset vertical position
        }

        // Course Details Section
        pdf.setFontSize(14);
        pdf.text('JeevaRaksha Course Details', 20, currentY);
        currentY += 10;

        pdf.setFontSize(12);
        pdf.text(`1. Course: ${cdata.course}`, 20, currentY);
        currentY += 10;
        pdf.text(`2. Institution: ${cdata.institution}`, 20, currentY);
        currentY += 10;
        pdf.text(`3. Duration of Course: ${cdata.cFromDate} to {finalObject.cToDate}`, 20, currentY);
        currentY += 10;
        pdf.text(`4. Days attended: ${cdata.daysAttended}`, 20, currentY);
        currentY += 10;
        pdf.text(`5. Attended as: ${cdata.attendee}`, 20, currentY);
        currentY += 20;

        if (currentY > 270) {
            pdf.addPage();
            currentY = 20;
        }

        // Bank Details Section
        pdf.setFontSize(14);
        pdf.text('Bank Details to be Filled', 20, currentY);
        currentY += 10;

        pdf.setFontSize(12);
        pdf.text(`1. Name of the Bank: ${hdata.pbank_name}`, 20, currentY);
        currentY += 10;
        pdf.text(`2. Address of the Bank: ${hdata.pbank_address}`, 20, currentY);
        currentY += 10;
        pdf.text(`3. Account Number: ${hdata.pacc_no}`, 20, currentY);
        currentY += 10;
        pdf.text(`4. IFSC Code: ${hdata.pifsc_code}`, 20, currentY);
        currentY += 10;
        pdf.text(`5. PAN Number: ${hdata.ppan_no}`, 20, currentY);
        currentY += 20;

        if (currentY > 270) {
            pdf.addPage();
            currentY = 20;
        }

        // Travel & Food Claim Section
        pdf.setFontSize(14);
        pdf.text('JEEVARAKSHA TRUST - TRAVEL & FOOD CLAIM FORM', 105, currentY, { align: 'center' });
        currentY += 20;

        pdf.setFontSize(12);
        pdf.text('Name: ___________________________', 20, currentY);
        currentY += 10;
        pdf.text('Program: ___________________________', 20, currentY);
        currentY += 20;

        // Table for Travel & Food Claim
        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'bold');
        pdf.autoTable({
            startY: currentY, // Start position for the table
            startX: 20, // Adjust left margin
            theme: 'grid',
            head: [
                ['Date', 'Place', 'Purpose', 'Transport', 'Food', 'Total'],
            ],
            body: Array(10).fill([
                '', // Empty rows for Date
                '', // Empty rows for Place
                '', // Empty rows for Purpose
                '', // Empty rows for Transport
                '', // Empty rows for Food
                '', // Empty rows for Total
            ]),
            styles: {
                halign: 'center',
                valign: 'middle',
            },
            columnStyles: {
                0: { cellWidth: 25 }, // Date
                1: { cellWidth: 40 }, // Place
                2: { cellWidth: 50 }, // Purpose
                3: { cellWidth: 20 }, // Transport
                4: { cellWidth: 20 }, // Food
                5: { cellWidth: 20 }, // Total
            },
            margin: { top: 20, bottom: 20, right: 20 }, // Adding top and bottom margin
        });

        // Signature and Notice Section
        currentY = pdf.lastAutoTable.finalY + 10; // Set Y to the end of the table
        pdf.setFontSize(10);
        pdf.text(
            'I __________________________ hereby certify that I have not claimed for the aforesaid reason for the said period from JeevaRaksha Trust or any other source as of date.',
            20,
            currentY,
            { maxWidth: 170 }
        );
        currentY += 20;
        pdf.text('Signature and Date: ___________________________', 20, currentY);
        currentY += 20;
        pdf.setFont('helvetica', 'italic');
        pdf.text('*Kindly attach copies of receipts (travel tickets, food bills) with this form.', 20, currentY);

        // Save the PDF
        pdf.save('HonorariumForm.pdf');
    };

    return (
        <div className={Prog.maincontainer}>
            <UserHeader Bindex={1} />
            <div>

                {loading ? (

                    <div style={{
                        margin: "20rem",
                        textAlign: "center"
                    }}>
                        <CircularProgress />
                    </div>

                ) : (<div style={{ fontFamily: 'Arial, sans-serif', lineHeight: 1.5, margin: 0, padding: '30px' }}>
                    <div ref={formRef} style={{ margin: '0 auto', padding: '20px' }}>
                        {/* Header Section */}
                        <header style={{ textAlign: 'center', marginBottom: '20px', display: "flex", gap: "10rem", alignItems: "center" }}>

                            <h2 style={{ fontSize: '18px', color: '#000', margin: 0 }}>Honorarium Form</h2>
                            <button
                                onClick={generateHonorariumPDF}
                                style={{
                                    marginTop: '20px',
                                    padding: '10px 20px',
                                    backgroundColor: '#007BFF',
                                    color: '#FFF',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                }}
                            >
                                Download as PDF
                            </button>

                        </header>

                        {/* Faculty Details Section */}
                        <section style={{ marginBottom: '20px' }}>
                            <h3 style={{ marginTop: '30px', marginBottom: '20px', color: '#333' }}>Course Director Details</h3>
                            <form>

                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "12rem 40rem",
                                    padding: "3rem",
                                }}>

                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>1. Name: </label>
                                    <input value={hdata.uname} style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" onChange={(e) =>
                                        sethdata({ ...hdata, uname: e.target.value })
                                    } />
                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>2. Address: </label>
                                    <input value={hdata.phaddress} style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" onChange={(e) =>
                                        sethdata({ ...hdata, phaddress: e.target.value })
                                    } />
                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>3. Contact No: </label>

                                    <input value={hdata.pcno} style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" onChange={(e) =>
                                        sethdata({ ...hdata, pcno: e.target.value })
                                    } />

                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>4. Designation: </label>
                                    <input value={hdata.pdesignation} style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" onChange={(e) =>
                                        sethdata({ ...hdata, pdesignation: e.target.value })
                                    } />
                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>5. Institution: </label>
                                    <input value={hdata.pinstitution} style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" onChange={(e) =>
                                        sethdata({ ...hdata, pinstitution: e.target.value })
                                    } />       </div>

                            </form>
                        </section>

                        {/* Course Details Section */}
                        <section style={{ marginBottom: '20px' }}>
                            <h3 style={{ marginTop: '30px', marginBottom: '20px', color: '#333' }}>JeevaRaksha Course Details</h3>
                            <form>
                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "12rem 40rem",
                                    padding: "3rem",
                                }}>
                                    <label style={{ fontWeight: 'bold', marginTop: '10px' }}>1. Course: </label>
                                    {/* <input style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" /> */}

                                    <select className="classInput_assign_assess" style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }}
                                        onChange={(e) =>
                                            setCdata({ ...cdata, course: e.target.value })
                                        }
                                    >
                                        <option value="0" selected="selected" disabled>
                                            Select Program
                                        </option>
                                        {userDetails.programs.map((value, index) => {
                                            return (
                                                <option value={value.uid} key={"curr" + index}>
                                                    {value.pname}
                                                </option>
                                            );
                                        })}

                                    </select>
                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>2. Institution: </label>
                                    <input value={hdata.pbank_name} style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" onChange={(e) =>
                                        setCdata({ ...cdata, institution: e.target.value })
                                    } />

                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>3. Start Date  </label>
                                    <input

                                        type="date"
                                        id="program-start"
                                        style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }}
                                        onChange={(e) =>
                                            setCdata({ ...cdata, pStart: e.target.value })
                                        }
                                    ></input>
                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>4.End Date  </label>
                                    <input
                                        style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }}
                                        type="date"
                                        id="program-start"
                                        value={hdata.pbank_name}
                                        onChange={(e) =>
                                            setCdata({ ...cdata, pend: e.target.value })
                                        }
                                    ></input>
                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>5. Days attended: </label>
                                    <input value={hdata.pbank_name} style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" onChange={(e) =>
                                        setCdata({ ...cdata, daysAttended: e.target.value })
                                    } />
                                    <label style={{ fontWeight: 'bold', marginTop: '10px' }}>6. Attended as: </label>
                                    <input value={hdata.pbank_name} style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" onChange={(e) =>
                                        setCdata({ ...cdata, attendee: e.target.value })

                                    } />
                                </div>
                            </form>
                        </section>

                        {/* Bank Details Section */}
                        <section>
                            <h3 style={{ marginTop: '10px', marginBottom: '10px', color: '#333' }}>Bank Details to be Filled</h3>
                            <form>

                                <div style={{
                                    display: "grid",
                                    gridTemplateColumns: "12rem 40rem",
                                    padding: "3rem",
                                }}>


                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>1. Name of the Bank: </label>
                                    <input value={hdata.pbank_name} style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" onChange={(e) =>
                                        sethdata({ ...hdata, pbank_name: e.target.value })
                                    } />
                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>2. Address of the Bank: </label>
                                    <input value={hdata.pbank_address} style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" onChange={(e) =>
                                        sethdata({ ...hdata, pbank_address: e.target.value })
                                    } />
                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>3. Account Number: </label>
                                    <input value={hdata.pacc_no} style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" onChange={(e) =>
                                        sethdata({ ...hdata, pacc_no: e.target.value })
                                    } />
                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>4. IFSC Code: </label>
                                    <input value={hdata.pifsc_code} style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" onChange={(e) =>
                                        sethdata({ ...hdata, pifsc_code: e.target.value })
                                    } />
                                    <label style={{ display: 'block', fontWeight: 'bold', marginTop: '10px' }}>5. PAN Number: </label>
                                    <input value={hdata.ppan_no} style={{ border: "none", width: "80%", boxShadow: "0px 0px 5px 0px lightgrey ", background: "white", height: "30px", marginBottom: "20px", fontSize: "15px", paddingLeft: "10px", fontWeight: "200px" }} type="text" id="name" required="required" onChange={(e) =>
                                        sethdata({ ...hdata, ppan_no: e.target.value })
                                    } />
                                </div>
                            </form>
                        </section>


                        <div style={{ display: "flex", gap: "2rem", marginLeft: "20rem" }}>
                            <button className={Prog.btn_color} onClick={() => { updateHonorariumForm() }}>
                                Save
                            </button>

                        </div>

                    </div>
                </div>)}



            </div>
        </div>
    )
}

export default TravelFoodForm
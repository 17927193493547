import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import { useNavigatorStatus } from "react-navigator-status";

import { awsSignIn, authData, awsSignOut } from "../redux/auth/authSlice";
import { SignIn, loginState, SignOut } from "../redux/auth/loginSlice";
import config from "../config/aws-exports";
import { Constants } from "../config/constants";

import LandingScreen from "../components/LandingScreen/LandingScreen";
import CreateQuiz from "../components/CreateQuiz/CreateQuiz";
//import Constants from '../config/constants';
//import logo from './logo.svg';

// import IndividualProgram from "../components/MyPrograms/IndividualProgram";

import MyApplicationLayout from "../Layouts/MyApplicationLayout";

import "./App.scss";
import Layout from "../Layouts/DashboardLayout";
import ProgramsLayout from "../Layouts/ProgramsLayout";
import ProgramsInfoLayout from "../Layouts/ProgramsInfoLayout";
import ManageBatchLayout from "../Layouts/ManageBatchLayout";
import FacultyLayout from "../Layouts/FacultyLayout";
import ContentLayout from "../Layouts/ContentLayout";
import GradingLayout from "../Layouts/GradingLayout";
import UserStatsLayout from "../Layouts/UserStatsLayout";
import UsersLayout from "../Layouts/UsersLayout";
import InstructorLayout from "../Layouts/InstructorLayout";
import ReportsLayout from "../Layouts/ReportsLayout";
import CourseViewLayout from "../Layouts/courseViewLayout";
import EmailTempLayout from "../Layouts/EmailTempLayout";
import DiscussionLayout from "../Layouts/DiscussionLayout";
import AnnouncementLayout from "../Layouts/AnnouncementLayout";
import EventsLayout from "../Layouts/EventsLayout";
import UserProgressLayout from "../Layouts/UserProgressLayout";
import GradingDetailsLayout from "../Layouts/GradingDetailsLayout";
import AssesmentDetailsLayout from "../Layouts/AssesmentDetailsLayout";
import AssignmentDetailsLayout from "../Layouts/AssignmentDetailsLayout";
import ApplicationDetailsLayout from "../Layouts/ApplicationDetailsLayout";
import FaqinsideLayout from "../Layouts/FaqinsideLayout";
import Warning from "../assets/svgjs/Warning";

import CourseLayout from "../Layouts/CourseLayout";

import ReportsAndAnalyticsLayout from "../Layouts/ReportsAndAnalyticsLayout";

import AllProgramsLayout from "../Layouts/AllProgramsLayout";
import AllProgramsInfoLayout from "../Layouts/ApplyProgramInfoLayout";
import ApplicationLayout from "../Layouts/ApplicationLayout";
import CourseCreationLayout from "../Layouts/courseCreationLayout";
import Faqs from "../modules/StaticPages/Faq/Faq";
import CareerDashboard from "../pages/CareerDashboard/CareerDashboard";
import CareerDashboardLayout from "../Layouts/CareerDashboardLayout";
import ApprovalsLayout from "../Layouts/ApprovalsLayout";
import ApplicationView from "../pages/ApplicationView/ApplicationView";
import ProfileView from "../pages/ProfileView/ProfileView";
import AllStudentsLayout from "../Layouts/AllstudentsLayout";
import Aside from "../modules/SideNav/Aside";
import ProfileApprovals from "../pages/Approvals/ProfileApprovals";
import JobApplicationApprovals from "../pages/Approvals/JobApplicationApprovals";
import AllStudentsView from "../pages/AllStudents/AllStudentsView";
import CreateFeedback from "../components/FeedBack/CreateFeedBack";
import ProgramsdetailsLayout from "../Layouts/ProgramsdetailsLayout";
import ProgramBatchDeatilsLayout from "../Layouts/ProgramBatchDeatilsLayout";
import BatchUserDetailsLayout from "../Layouts/BatchUserDetailsLayout";
import TravelFoodFormLayout from "../Layouts/TravelFoodFormLayout";

function App() {
  const [api, setApi] = useState([{}]);
  const [user, setUser] = useState("");
  const userAuthStateValue = useSelector(authData);
  const loginStateValue = useSelector(loginState);
  const dispatch = useDispatch();
  const isOnline = useNavigatorStatus();

  console.log("userAuthStateValue", userAuthStateValue);

  useEffect(() => {
    return () => { };
  }, []);

  async function signIn() {
    try {
      const user = await Auth.signIn("sumana@enhanzed.com", "qwerty@1");

      const info = await Auth.currentUserInfo();
      let userdata = user.attributes;
      userdata.username = user.username;
      userdata.id = info.id;
      setUser(JSON.stringify(userdata));
      dispatch(awsSignIn(userdata));
    } catch (error) {
      console.log("error signing in", error);
    }
  }

  function Home() {
    return (
      <div>
        <button onClick={signIn}>Login</button>
        <pre>{JSON.stringify(userAuthStateValue, null, 2)}</pre>
        <button
          onClick={() => {
            Auth.signOut();
            dispatch(awsSignOut());
          }}
        >
          signOut
        </button>
        <h1>Home</h1>
        <nav>
          <Link to="/">Home</Link>
          {" | "}
          <Link to="/LandingScreen">LandingScreen</Link>
          {" | "}
          <Link to="/about">About</Link>
          {" | "}
          <Link to="/dashboard">Dashboard </Link>
          {" | "}
          <Link to="/event">Events </Link>
          {" | "}
          <Link to="/MyPrograms/programs">Programs </Link>
          {" | "}
          <Link to="/myPrograms">Myprograms</Link>
          {" | "}
          <Link to="/course">Course</Link>
          {" | "}
          <Link to="/myProg">PreLogin</Link>
          {" | "}
          <Link to="/event">Event</Link>
        </nav>

        <div
          style={{ padding: 20, height: 300, width: "100%", overflow: "auto" }}
        ></div>
      </div>
    );
  }

  function About() {
    return (
      <div>
        <button
          onClick={() => {
            Auth.signOut();
            dispatch(awsSignOut());
          }}
        >
          signOut
        </button>
        <h1>About</h1>
        <nav>
          <Link to="/">Home</Link> {" | "}
          <Link to="/LandingScreen">LandingScreen</Link> {" | "}
          <Link to="/about">About</Link>
        </nav>
      </div>
    );
  }

  const Alert = ({ isOnline }) => {
    const [showAlert, setShowAlert] = React.useState(false);

    React.useEffect(() => {
      let isMounted = true;

      if (isOnline && showAlert && isMounted) {
        setShowAlert(true);

        setTimeout(() => {
          if (isMounted) {
            setShowAlert(false);
          }
        }, 4000);
      }

      if (!isOnline && isMounted) {
        setShowAlert(true);
      }

      return () => {
        isMounted = false;
      };
    }, [isOnline]);

    return (
      <div
        style={{
          fontFamily: `sans-serif`,
          fontWeight: "500",
          fontSize: "14px",
          padding: "5px 10px",
          width: "fit-content",
          height: "fit-content",
          position: "absolute",
          right: "35%",
          top: 10,
          zIndex: 1000,
          border: "1px solid red",
          borderRadius: "5px",
          background: "red",
        }}
      >
        {showAlert && (
          <div
            style={{
              color: "white",
              background: isOnline ? `red` : "red",
            }}
          >
            <Warning style={{ height: "4%", width: "4%" }} />
            {"  "}
            {isOnline
              ? `You are online`
              : `You are offline! Please check your connection`}
          </div>
        )}
      </div>
    );
  };
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div className="app">
      {isOnline === false ? <Alert isOnline={isOnline} /> : null}
      {loginStateValue !== 0 && (
        <Aside toggled={toggled} handleToggleSidebar={handleToggleSidebar} />
      )}
      <Routes>
        {loginStateValue === 0 ? (
          <>
            <Route path="/" element={<LandingScreen />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="*" element={<LandingScreen />} />
          </>
        ) : (
          <>
            {userAuthStateValue?.role !== "PO" ? (
              <>
                {(userAuthStateValue.role === "Admin" || userAuthStateValue.role === "CDirector" || userAuthStateValue?.uData?.roles?.includes('CDirector')) ? (
                  <>
                    {" "}
                    <Route path="/" element={<Layout />} />
                    <Route path="/dashboard" element={<Layout />} />
                  </>
                ) : (
                  <>
                    <Route path="/" element={<ProgramsLayout />} />
                  </>
                )}

                <Route path="/LandingScreen" element={<LandingScreen />} />
                <Route path="/Programs" element={<ProgramsLayout />} />
                <Route path="/ProgramsInfo" element={<ProgramsInfoLayout />} />
                <Route path="/ManageBatch" element={<ManageBatchLayout />} />
                <Route path="/CreateFeedback" element={<CreateFeedback />} />
                <Route path="/Content" element={<ContentLayout />} />
                <Route path="/Faculty" element={<FacultyLayout />} />
                <Route path="/Grading" element={<GradingLayout />} />
                <Route path="/Reports" element={<ReportsLayout />} />
                <Route path="/UserStats" element={<UserStatsLayout />} />

                {(userAuthStateValue.role === "Admin" || userAuthStateValue.role === "CDirector" || userAuthStateValue?.uData?.roles?.includes('CDirector')) && (
                  <Route path="/users" element={<UsersLayout />} />
                )}
                {(userAuthStateValue.role === "Admin" || userAuthStateValue.role === "CDirector" || userAuthStateValue?.uData?.roles?.includes('CDirector')) && (
                  <Route path="/instructors" element={<InstructorLayout />} />
                )}
                <Route path="/courseView" element={<CourseViewLayout />} />
                <Route path="/emailtemplate" element={<EmailTempLayout />} />
                <Route path="/discussion" element={<DiscussionLayout />} />
                <Route path="/announcement" element={<AnnouncementLayout />} />
                <Route path="/events" element={<EventsLayout />} />
                <Route path="/userprogress" element={<UserProgressLayout />} />
                <Route
                  path="/gradingdetails"
                  element={<GradingDetailsLayout />}
                />
                <Route
                  path="/applicationdetails"
                  element={<ApplicationDetailsLayout />}
                />
                <Route
                  path="/courseCreation"
                  element={<CourseCreationLayout />}
                />
                <Route path="/createQuiz" element={<CreateQuiz />} />
                <Route
                  path="/assesmentdetails"
                  element={<AssesmentDetailsLayout />}
                />
                <Route
                  path="/assignmentdetails"
                  element={<AssignmentDetailsLayout />}
                />

                <Route path="/course" element={<CourseLayout />} />

                <Route path="/application" element={<ApplicationLayout />} />
                <Route path="/faqs" element={<FaqinsideLayout />} />

                <Route
                  path="/reportsAndAnalytics"
                  element={<ReportsAndAnalyticsLayout />}
                />

                {/* // <Route path="/myApplication/application" element={<Application />} /> */}
                <Route path="/allPrograms" element={<AllProgramsLayout />} />
                <Route
                  path="/applyProgram"
                  element={<AllProgramsInfoLayout />}
                />
                <Route
                  path="/programsdetails"
                  element={<ProgramsdetailsLayout />}
                />
                <Route
                  path="/programBatchDeatils"
                  element={<ProgramBatchDeatilsLayout />}
                />

                <Route
                  path="/batchUserDetails"
                  element={<BatchUserDetailsLayout />}
                />

                <Route
                  path="/travelfoodform"
                  element={<TravelFoodFormLayout />}
                />


                <Route
                  path="/myApplication/application"
                  element={<ApplicationLayout />}
                />

                <Route path="*" element={<LandingScreen />} />
              </>
            ) : (
              <>
                <Route path="/dashboard" element={<CareerDashboardLayout />}>
                  <Route path="application" element={<ApplicationView />} />
                  <Route path="profile" element={<ProfileView />} />
                </Route>
                <Route
                  path="/jobapplicationapprovals"
                  element={<JobApplicationApprovals />}
                >
                  <Route path="application" element={<ApplicationView />} />
                  <Route path="profile" element={<ProfileView />} />
                </Route>
                <Route path="/profileapprovals" element={<ProfileApprovals />}>
                  <Route path="application" element={<ApplicationView />} />
                  <Route path="profile" element={<ProfileView />} />
                </Route>
                <Route path="/allstudents" element={<AllStudentsLayout />}>
                  <Route path="view" element={<AllStudentsView />} />
                </Route>

                <Route
                  path="*"
                  element={<Navigate replace to="/dashboard" />}
                />
              </>
            )}
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;

// import React from 'react';

// import Layout from '../Layout';

// import './App.scss';

// function App() {
//   return (
//     <div>
//       <Layout />
//     </div>
//   );
// }

// export default App;

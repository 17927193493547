import React, { useState } from 'react'
import ProgramBatchDetails from '../components/Programs/ProgramBatchDetails';
const ProgramsdetailsLayout = () => {
    const rtl = false;

    const [toggled, setToggled] = useState(false);

    const handleToggleSidebar = (value) => {
        setToggled(value);
    };

    return (
        <>
            {/* <Aside
                  rtl={rtl}
                  toggled={toggled}
                  handleToggleSidebar={handleToggleSidebar}
              /> */}
            <ProgramBatchDetails handleToggleSidebar={handleToggleSidebar} />
        </>
    )
}

export default ProgramsdetailsLayout
// Dependencies imports
import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import Skeleton from "@material-ui/lab/Skeleton";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/styles";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import DoughnutChart from "./DoughnutChart";
import UserStatsGrid from "./UserStatsGrid";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import AddUser from "./AddUser";
import SendNotification from "./SendNotification";
import DeleteUser from "./DeleteUser";
import UserBulkUpload from "../Users/UserBulkUpload";
import swal from "sweetalert";
// Styles imports
import Use from "./UserStats.module.scss";
import { CircularProgress } from "@material-ui/core";

// Materail Styles for Modal
const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: config.main_color_1,
      fontSize: "16px",
      color: Constants.app_color,
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});

// Start of User Stats component
const UserStats = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [totalUser, setTotalUser] = useState("");
  const [totaActiveUsers, setTotaActiveUsers] = useState("");
  const [totalDesktopUsers, setTotalDesktopUsers] = useState("");
  const [totalIphoneUsers, setTotalIphoneUsers] = useState("");
  const [totalAndroidUsers, setTotalAndroidUsers] = useState("");
  const [genderChartData, setGenderChartData] = useState([]);
  const [openUser, setOpenUser] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  const [selectionModel, setSelectionModel] = useState([]);
  const [mailUserList, setMailUserList] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  let userDetails = useSelector(authData);

  useEffect(() => {
    getFaculty();
    breadcrumb();
    return () => { };
  }, []);

  // Api call to get faculty details
  async function getFaculty() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        pid: userDetails.curprgcou.pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

    };
    try {
      const users = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USERS,
        bodyParam
      );
      convertData(users.body);
    } catch (error) {
      console.error(error);
    }
  }

  function convertData(data) {
    let temp = [];

    console.log("userDetails", userDetails)

    for (let i = 0; i < data.length; i++) {
      const userData = data[i];

      // Check if batchid exists and matches userDetails.curprgcou.bid
      if (userData.batchid && userData.batchid?.includes(userDetails.curprgcou.bid)) {

        const name = userData.last_name ? `${userData.first_name} ${userData.last_name}` : userData.first_name;

        const obj = {
          id: i,
          first_name: name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
          emailid: userData.emailid,
          eid: userData.eid,
          ur: userData.ur.toLowerCase(),
          regi_dtls: userData.regi_dtls,
          ur_id: userData.ur_id,
          send: userData.send,
          start_date: parseInt(userData.start_date),
          apr_status: userData.apr_status,
          payment: userData.payment,
          bid: userDetails.curprgcou.bid,
          pid: userDetails.curprgcou.pid,
          cname: userData.cname,
          roles: userData.roles,
          gen: userData.gen === 1 ? "Male" : userData.gen === 2 ? "Female" : "Unknown"
        };

        // Check if the user has the role 'User' and batchid matches
        if ((obj.ur === "user" || obj.ur === "User") && obj.roles && obj.roles.some(role => role.role === 'User' && role.batchid === userDetails.curprgcou.bid)) {
          temp.push(obj);
        }
      }
    }
    let tdev = 0,
      android = 0,
      iphone = 0,
      desktop = 0,
      male = 0,
      female = 0,
      unknown = 0,
      activeusers = 0;
    let instructorList = [],
      userList = [];
    for (let k = 0; k < data.length; k++) {
      if (data[k].ur.toLowerCase() === "Instructor".toLowerCase()) {
        instructorList.push(data[k]);
      } else if (data[k].ur.toLowerCase() === "User".toLowerCase()) {
        userList.push(data[k]);

        if (
          data[k].batchid !== undefined &&
          data[k].batchid !== null &&
          data[k].batchid.includes(userDetails.curprgcou.bid)
        ) {
          if (
            data[k].ur.toLowerCase() === "User".toLowerCase() &&
            data[k].la !== null
          )
            activeusers++;

          if (
            data[k].dev === undefined ||
            data[k].dev === null ||
            data[k].dev === 2
          )
            desktop++;
          else if (data[k].dev === 1) android++;
          else iphone++;
          if (data[k].gen === 1) male++;
          else if (data[k].gen === 2) female++;
          else unknown++;

          tdev++;
        }
      }
    }
    let tusers = temp.length;
    let ios, andrd, dtop;
    let gender = [];
    gender.push(male, female, unknown);
    if (userList.length > 0) {
      ios = (+iphone / +tdev) * 100;
      if (ios === Math.floor(ios)) ios = ios;
      else ios = ios.toFixed(2);

      andrd = (+android / +tdev) * 100;

      if (andrd === Math.floor(andrd)) andrd = andrd;
      else andrd = andrd.toFixed(2);

      dtop = (+desktop / +tdev) * 100;
      if (dtop === Math.floor(ios)) dtop = dtop;
      else dtop = dtop.toFixed(2);
    } else {
      ios = 0;
      andrd = 0;
      dtop = 0;
    }


    console.log("temp", temp)
    setUserData(temp);
    setAllUsers(data);
    setTotalUser(tusers);
    setTotaActiveUsers(activeusers);
    setTotalDesktopUsers(dtop);
    setTotalIphoneUsers(ios);
    setTotalAndroidUsers(andrd);
    setGenderChartData(gender);
    setLoading(false);
  }

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: "Users",
      path: "/UserStats",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  const handleEmailOpen = () => {
    recvDataMail(selectionModel);
    setOpenEmail(true);
  };

  const handleEmailClose = () => {
    setOpenEmail(false);
  };

  const handleUserOpen = () => {
    setOpenUser(true);
  };

  const handleUserClose = () => {
    setOpenUser(false);
  };
  const handleBulkOpen = () => {
    setOpenBulk(true);
  };

  const handleBulkClose = () => {
    setOpenBulk(false);
  };
  const handleOpenDelete = () => {
    recvDataMail(selectionModel);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  async function downloadcsv() {
    const response = await fetch("batchuserlist.csv");
    const data = await response.text();
    const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
    const url = window.URL.createObjectURL(blob);
    let dwldLink = document.createElement("a");
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", "batchuserlist.csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  function recvDataMail(indexArr) {
    let rowObj = {};
    let userList = [];

    for (let i = 0; i < indexArr.length; i++) {
      let idx = userData
        .map(function (e) {
          return e.id;
        })
        .indexOf(indexArr[i]);
      if (idx !== -1) {
        rowObj = userData[idx];
        userList.push(rowObj);
      }
    }
    setMailUserList(userList);

  }
  const handleApprovePayment = async () => {
    recvDataMail(selectionModel);
    try {
      const bodyParam = {
        body: {
          atype: 1,
          bid: userDetails.curprgcou.bid,
          pid: userDetails.curprgcou.pid,
          ur_ids: mailUserList,
          payment: true,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/addUpdateRegistration",
        bodyParam
      );
      swal({
        title: "Success",
        text: "Payment Approved Successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {

          getFaculty();
        }
      });
    } catch (error) { }
  };

  return (
    <div className={Use.containermain}>
      <UserHeader Bindex={2} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openBulk}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openBulk}>
          <UserBulkUpload handleClose={handleBulkClose} type="batch"
            rows={userData}
            getFaculty={getFaculty}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openUser}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openUser}>
          <AddUser
            handleUserClose={handleUserClose}
            getFaculty={getFaculty}
            userData={userData}
            allUsers={allUsers}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEmail}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEmail}>
          <SendNotification
            handleEmailClose={handleEmailClose}
            mailUserList={mailUserList}
            toTenants={true}
            setSelectionModel={setSelectionModel}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <DeleteUser
            handleCloseDelete={handleCloseDelete}
            mailUserList={mailUserList}
            getFaculty={getFaculty}
            setSelectionModel={setSelectionModel}
          />
        </Fade>
      </Modal>

      <div className={Use.topsection}>
        <div className={Use.users}>
          <h3 className={Use.leftsection}>Total users</h3>
          <Paper
            className={Use.count}
            style={{
              height: "140px",
              width: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h4 className={Use.number}>
              {loading === false ? (
                totalUser
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton variant="rect" width={50} height={28} />{" "}
                </div>
              )}
            </h4>
          </Paper>
        </div>
        <div className={Use.today}>
          <h3 className={Use.leftsection}>Active today</h3>
          <Paper
            className={Use.count}
            style={{
              height: "140px",
              width: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h4 className={Use.number}>
              {loading === false ? (
                totaActiveUsers
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton variant="rect" width={50} height={28} />{" "}
                </div>
              )}
            </h4>
          </Paper>
        </div>

        <div className={Use.gender}>
          <h3 className={Use.leftsection}>Users by gender</h3>
          <Paper
            style={{ width: "350px", height: "140px", paddingTop: "20px" }}
          >
            <div className={Use.graph}>

              {loading === false ? (
                <DoughnutChart genderChartData={genderChartData} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton variant="rect" width={140} height={80} />{" "}
                </div>
              )}
            </div>
          </Paper>
        </div>
      </div>
      <div className={Use.bottomsection}>
        <div className={Use.topbar}>
          <div className={Use.headercontainer}>
            <h3 className={Use.header}>User activity</h3>
          </div>
          <div className={Use.rightsection}>
            {selectionModel != undefined && selectionModel.length !== 0 ? (
              <button
                className={
                  window.navigator.onLine === true
                    ? Use.btn_color
                    : Use.btn_colordis
                }
                disabled={!window.navigator.onLine}
                onClick={() => handleApprovePayment()}
              >
                Bypass Payment
              </button>
            ) : null}
            {selectionModel != undefined && selectionModel.length !== 0 ? (
              <button
                className={
                  window.navigator.onLine === true
                    ? Use.btn_color
                    : Use.btn_colordis
                }
                disabled={!window.navigator.onLine}
                onClick={() => {
                  handleEmailOpen();
                }}
              >
                Send email/notification
              </button>
            ) : null}
            {selectionModel != undefined && selectionModel.length !== 0 ? (
              <button
                className={
                  window.navigator.onLine === true
                    ? Use.btn_color
                    : Use.btn_colordis
                }
                disabled={!window.navigator.onLine}
                onClick={() => {
                  handleOpenDelete();
                }}
              >
                Remove User
              </button>
            ) : null}

            <a
              style={{ fontSize: "13px", cursor: "pointer", color: "blue" }}
              onClick={downloadcsv}
            >
              Download Format
            </a>
            <button className={
              window.navigator.onLine === true
                ? Use.btn_color
                : Use.btn_colordis
            }
              disabled={!window.navigator.onLine}
              onClick={() => {
                handleBulkOpen();
              }}
            >
              Bulk Upload
            </button>

            <button
              className={
                window.navigator.onLine === true
                  ? Use.btn_color
                  : Use.btn_colordis
              }
              disabled={!window.navigator.onLine}
              onClick={() => {
                handleUserOpen();
              }}
            >
              Add users
            </button>
          </div>
        </div>
      </div>
      <div className={Use.datagrid}>
        <div style={{ height: 400, width: "100%" }} className={classes.root}>
          <Paper>
            {loading === false ? (
              <UserStatsGrid
                userData={userData}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                getFaculty={getFaculty}
                sendLoading={sendLoading}
                setSendLoading={setSendLoading}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  paddingTop: "40px",
                  height: "400px",
                }}
              >
                <Skeleton variant="rect" width="90%" height={300} />{" "}
              </div>
            )}
          </Paper>
        </div>
      </div>
      <Backdrop open={sendLoading} style={{ backgroundColor: "white" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default UserStats;

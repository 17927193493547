import React, { useState, useEffect } from 'react'
import { Tab } from "semantic-ui-react";
import { makeStyles } from "@material-ui/styles";
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { useSelector, useDispatch } from "react-redux";

// Local imports
import UserHeader from '../Header/UserHeader/UserHeader'
import Prog from "./Programs.module.scss";
import config from "../../config/aws-exports";
import { awsSignIn, authData } from "../../redux/auth/authSlice";

function CustomToolbar() {
    return (
        <GridToolbarContainer
            style={{ display: "flex", justifyContent: "flex-end" }}
        >
            <GridToolbar />
        </GridToolbarContainer>
    );
}

const useStyles = makeStyles({
    root: {
        "& .super-app-theme--header": {
            backgroundColor: config.main_color_1,
            fontSize: "16px",
            color: "rgb(255,255,255)",
            fontFamily: "nunito",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    ".MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
        '& .MuiDataGrid-sortIcon': {
            // Add your custom styles for the sort icon here
            color: 'white', // Example: Change the color to red
            fontSize: '20px', // Example: Change the font size
        },
    },
});
const BatchUserDetails = () => {
    const userDetails = useSelector(authData);
    const [rows, setRows] = useState([]);

    // Use useEffect to set rows when userDetails changes
    useEffect(() => {
        if (userDetails && Array.isArray(userDetails.batchUsers)) {
            const temp = userDetails.batchUsers.map((user, index) => ({
                id: index + 1,
                name: `${user.first_name} ${user.last_name}`,
                rno: user.rno,
            }));
            setRows(temp);
        }
    }, [userDetails]); // Dependency array ensures this runs only when userDetails changes

    const classes = useStyles();

    const tcolumns = [
        {
            field: "id",
            headerName: "Sl No.",
            flex: 1,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "rno",
            headerName: "Register Number",
            flex: 1,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "col14",
            headerName: "Paid",
            flex: 1,
            headerClassName: "super-app-theme--header",
        },
    ];

    const downloadMothlyReport = () => {


        console.log("rows", rows)



        let csvData = "Sl No , User Name, rno\n";

        // Generate CSV content
        rows.forEach(row => {
            csvData += `${row.id || ""},${row.name || ""},${row.rno || ""}\n`;
        });

        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "MonthlyReport.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);



    };

    const panes = [
        {
            menuItem: "Certification Report",
            render: () => (
                <Tab.Pane active={true}>

                    <div>
                        <div style={{ textAlign: "right" }}>
                            <button className={Prog.btn_color} onClick={() => downloadMothlyReport()}>
                                Download Report
                            </button>
                        </div>

                    </div>
                    <div
                        style={{ height: 500, width: "100%", padding: "2rem" }}
                        className={classes.root}
                    >
                        <DataGrid
                            rows={rows}
                            columns={tcolumns}
                            className={classes[".MuiDataGrid-root"]}
                            disableSelectionOnClick
                            sx={{
                                fontSize: '13px',
                            }}
                            components={{ Toolbar: CustomToolbar }}
                            disableColumnMenu
                        />
                    </div>
                </Tab.Pane>
            ),
        },
    ];

    return (
        <div className={Prog.maincontainer}>
            <UserHeader Bindex={1} />
            <div style={{ margin: "1rem" }}>
                <Tab panes={panes} />
            </div>
        </div>
    );
};

export default BatchUserDetails;
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { jsPDF } from 'jspdf';
import { CircularProgress } from "@material-ui/core";
// Local imports
import UserHeader from '../Header/UserHeader/UserHeader'
import Prog from "./Programs.module.scss";
import config from "../../config/aws-exports";
import { awsSignIn, authData } from "../../redux/auth/authSlice";

function CustomToolbar() {
    return (
        <GridToolbarContainer
            style={{ display: "flex", justifyContent: "flex-end" }}
        >
            <GridToolbar />
        </GridToolbarContainer>
    );
}

const useStyles = makeStyles({
    root: {
        "& .super-app-theme--header": {
            backgroundColor: config.main_color_1,
            fontSize: "16px",
            color: "rgb(255,255,255)",
            fontFamily: "nunito",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    ".MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
        '& .MuiDataGrid-sortIcon': {
            // Add your custom styles for the sort icon here
            color: 'white', // Example: Change the color to red
            fontSize: '20px', // Example: Change the font size
        },
    },
});


const ProgramBatchDetails = () => {

    const classes = useStyles();
    let navigate = useNavigate();
    let userDetails = useSelector(authData);
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    console.log("userDetails", userDetails)



    const tcolumns = [
        {
            field: "id",
            headerName: "Sl No.",
            flex: 1,
            headerClassName: "super-app-theme--header",
            // renderCell: (params) => {
            //     return (
            //         <p onClick={() => handleRowClick(params)}>{params.value}</p>
            //     )
            // }
        },
        {
            field: "bname",
            headerName: "Batch Name",
            flex: 1,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <p onClick={() => handleRowClick(params)}>{params.value}</p>
                )
            }
        },
        {
            field: "location",
            headerName: "Location",
            flex: 1,
            headerClassName: "super-app-theme--header",
            // renderCell: (params) => {
            //   return (
            //     <p onClick={() => handleRowClick(params)}>{params.value}</p>
            //   )
            // }
        },
        {
            field: "university",
            headerName: "University",
            flex: 1,
            headerClassName: "super-app-theme--header",
            // renderCell: (params) => (
            //   <p onClick={() => handleRowClick()}>
            //     {params.value.toUpperCase()}
            //   </p>
            // ),
        },
        {
            field: "Start_date",
            headerName: "Start Date",
            flex: 1,
            headerClassName: "super-app-theme--header",
            // renderCell: (params) => {
            //   return (
            //     <p onClick={() => handleRowClick(params)}>{params.value}</p>
            //   )
            // }
        },
        {
            field: "users",
            headerName: "No. of Users",
            flex: 1,
            headerClassName: "super-app-theme--header",
            // renderCell: (params) => (
            //   <p onClick={() => handleRowClick(params)}>
            //     {params.value === null ? '' : (params.value === 1 ? 'Male' : (params.value === 2 ? 'Female' : params.value === 3 ? 'Trans Gender' : 'Others'))}
            //   </p>
            // ),
        },

    ];

    useEffect(() => {

        getBatchDetails()

    }, []);


    async function getBatchDetails() {
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                schema: config.schema,
                pid: userDetails.prog.pid,

            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };

        setLoading(true)
        try {
            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                '/getBatchReport',
                bodyParam
            );

            let temp = [];

            if (response.body.batchs != undefined || response.body.batchs != null) {

                for (let i = 0; i < response.body.batchs.length; i++) {
                    let users = []
                    let obj = {};
                    obj.id = i + 1;
                    obj.bid = response.body.batchs[i].bid
                    obj.bname = response.body.batchs[i].bname;

                    if (response.body.batchs[i].location != undefined) {
                        obj.location = response.body.batchs[i].location;
                    }

                    if (response.body.batchs[i].university != undefined) {


                        obj.university = response.body.batchs[i].university;
                    }


                    obj.Start_date = moment(parseInt(response.body.batchs[i].start_date, 10)).format("DD-MM-YYYY");

                    for (let j = 0; j < userDetails.usersData.length; j++) {

                        if (userDetails.usersData[j].batchid?.includes(response.body.batchs[i].bid)) {
                            users.push(userDetails.usersData[j])

                        }
                    }

                    obj.users = users.length;

                    obj.batchusers = users

                    temp.push(obj)

                }

            }

            console.log("Temp", temp)

            setRows(temp)


        } catch (error) {

            console.error(error);
        }

        setLoading(false)
    }

    const handleRowClick = (params) => {

        let sdata = { ...userDetails };
        console.log("params", params)
        sdata.batchUsers = params.row.batchusers;

        console.log("userDetails", params)
        dispatch(awsSignIn(sdata));


        navigate("/batchUserDetails");

    };


    const downloadMothlyReport = () => {


        console.log("rows", rows)

        let data = rows.map(obj => {
            delete obj.batchusers;
            return obj;
        })

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Months are 0-based, so add 1
        const currentYear = currentDate.getFullYear();

        // Filter the batches array
        const filteredBatches = data.filter(batch => {
            const [, month, year] = batch.Start_date.split('-').map(Number);
            return month == currentMonth && year == currentYear;
        });


        console.log(filteredBatches);

        let csvData = "Batch Name,Location,University,Start Date,No of Users\n";

        // Generate CSV content
        filteredBatches.forEach(row => {
            csvData += `${row.bname || ""},${row.location || ""},${row.university || ""},${row.Start_date || ""},${row.users || ""}\n`;
        });

        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "Monthly Certificate Report.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    };


    const handleExportPDF = () => {
        const doc = new jsPDF();


        console.log("rows", rows)

        let data = rows.map(obj => {
            delete obj.batchusers;
            return obj;
        })

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Months are 0-based, so add 1
        const currentYear = currentDate.getFullYear();

        // Filter the batches array
        const filteredBatches = data.filter(batch => {
            const [, month, year] = batch.Start_date.split('-').map(Number);
            return month == currentMonth && year == currentYear;
        });

        const getCurrentMonthYear = () => {
            const currentDate = new Date();
            const options = { month: 'long', year: 'numeric' }; // Format as "Jan 2025"
            return currentDate.toLocaleString('en-US', options);
        };


        let current_Month = getCurrentMonthYear()

        console.log(filteredBatches);

        const totalUsers = filteredBatches.reduce((total, program) => total + program.users, 0);

        console.log("Total Users:", totalUsers);
        const tableColumn = ['Batch Name', 'Location', 'University', 'Start Date', 'No of Users'];
        const tableRows = filteredBatches.map(row => [
            row.bname,
            row.location,
            row.university,
            row.Start_date,
            row.users
        ]);

        doc.text(`We are glad to inform you that a total of ${filteredBatches.length}- JeevaRaksha ${userDetails?.prog?.pname} was conducted in the month of ${current_Month}. A total of ${totalUsers} participants were trained and certified.`, 12, 10, { maxWidth: 170 }); // Add title

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30,
        });
        doc.save("MonthlyReport.pdf");
    };

    return (
        <div className={Prog.maincontainer}>
            <UserHeader Bindex={1} />

            <div style={{ margin: "2rem", textAlign: "right" }}>
                <button className={Prog.btn_color} onClick={() => handleExportPDF()}>
                    Monthly Certificate Report
                </button>
            </div>

            {loading ? (

                <div style={{
                    margin: "20rem",
                    textAlign: "center"
                }}>
                    <CircularProgress />
                </div>

            ) : (<div style={{ height: 500, width: "100%" }} className={classes.root}>
                <DataGrid
                    rows={rows}
                    columns={tcolumns}
                    className={classes[".MuiDataGrid-root"]}
                    disableSelectionOnClick
                    sx={{
                        fontSize: '13px',
                    }}
                    components={{ Toolbar: CustomToolbar }}
                    disableColumnMenu
                />
            </div>)}



        </div>
    )
}

export default ProgramBatchDetails
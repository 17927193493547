// Dependencies imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
//import { awsSignIn, authData } from "../../redux/auth/authSlice";

import config from "../../config/aws-exports";
//import { Constants } from "../../config/constants";

//import { generate } from "shortid";
import { DataGrid } from '@mui/x-data-grid';

import { makeStyles, CircularProgress } from "@material-ui/core";
import Swal from "sweetalert2";
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';

// import { GridToolbarContainer, GridToolbar } from '@material-ui/data-grid';



const useStyles = makeStyles({
    root: {
        '& .super-app-theme--header': {
            background: config.main_color_1,
            fontSize: "16px",
            color: 'rgb(255,255,255)',
            fontFamily: "nunito"
        },
    },
    ".MuiDataGrid-root": {
        fontSize: "14px !important",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
        width: "100%",
        overflow: 'auto'

    }
});

// Start of Reports component
const StudentView = (props) => {
    let { userData, selectionModel, setSelectionModel, columns, showApproveButton, approveObject } = props;

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();



    const handleReset = (args) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to Approve the Theory test!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            dangerMode: true,
        }).then((result) => {
            if (result.isConfirmed) {
                approveTest();
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });

    };


    async function approveTest() {
        const bodyParam = {
            body: {
                schema: config.schema,
                approveObj: approveObject,
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        setLoading(true)
        console.log("resetUserresetUserresetUserresetUserresetUserresetUserresetUserresetUserresetUserresetUser", bodyParam)
        try {
            let response = await API.post(
                config.aws_cloud_logic_custom_name,
                '/approveTest',
                bodyParam
            );

            if (response.code === 200 || response.code === "200") {
                Swal.fire({
                    title: "Success!",
                    text: "The Theory test has been approved successfully.",
                    icon: "success",

                });
            }

        } catch (error) {
            console.error(error);
        }

        setLoading(false)

    }

    return (
        <div style={{ height: 400, width: '100%' }}>


            {loading && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress style={{ color: config.main_color_1 }} />
                </div>
            )}


            {!loading && (<div>
                {showApproveButton && <div style={{ textAlign: "right" }}>

                    <button style={{
                        background: 'var(--button-background)',
                        border: 'none',
                        borderRadius: '5px',
                        height: '30px',
                        width: 'fit-content',
                        color: 'var(--button-text)',
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        padding: '0px 20px',
                        margin: "1rem",
                        textAlign: "right",
                        cursor: "pointer"


                    }} onClick={handleReset}>Approve</button>
                </div>}



                <div style={{ height: 400, width: '500' }} >
                    <DataGrid
                        rows={userData}
                        columns={columns}
                        disableSelectionOnClick
                        onRowSelected={e => console.log(e)}
                        onSelectionModelChange={(newSelection) => {
                            console.log(newSelection);
                            setSelectionModel(newSelection);
                        }}
                        selectionModel={selectionModel}

                        className={classes['.MuiDataGrid-root']}
                    />
                </div>
            </div>)

            }





        </div>
    );
};

export default StudentView;
